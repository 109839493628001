import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

export class Nav extends Component {
    render() {
        return (
            <nav>
                <div className="container mb-2">
                    <div className="row pb-1">
                        <h2 className="col-12 pt-1 mb-0">MICHELLE ALEXANDRA ØSTERBYE HANSEN</h2>
                        <h5 className="col-md-12 col-lg-6 mb-1">COMPUTER SCIENCE STUDENT</h5>
                        <ul className="col-md-12 col-lg-6 d-flex justify-content-center justify-content-sm-end mb-1">
                            <div>
                                <li className="nav_link"><NavLink exact to="/">HOME</NavLink></li>
                                <li className="nav_link"><NavLink to="/projects">PROJECTS</NavLink></li>
                                <li className="nav_link"><NavLink to="/about">ABOUT ME</NavLink></li>
                                <li className="last_nav_link"><NavLink to="/contact">CONTACT</NavLink></li>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Nav
