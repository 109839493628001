import React, { PureComponent } from 'react'
import ModalImage from "react-modal-image"

// images
import Current_1 from "../images/Current_1.png"
import Current_2 from "../images/Current_2.png"
import Current_3 from "../images/Current_3.png"
import Current_4 from "../images/Current_4.png"
import Current_1_Small from "../images/Current_1_Small.png"
import Current_2_Small from "../images/Current_2_Small.png"
import Current_3_Small from "../images/Current_3_Small.png"
import Current_4_Small from "../images/Current_4_Small.png"
import RPG_Game1 from "../images/RPG_game_1.png"
import RPG_Game2 from "../images/RPG_game_2.png"
import RPG_Game3 from "../images/RPG_game_3.png"
import RPG_Game1_Small from "../images/RPG_game_1_small.png"
import RPG_Game2_Small from "../images/RPG_game_2_small.png"
import RPG_Game3_Small from "../images/RPG_game_3_small.png"
import Uni1 from "../images/Uni_game_1.png"
import Uni2 from "../images/Uni_game_2.png"
import Uni3 from "../images/Uni_game_3.png"
import Uni4 from "../images/Uni_game_4.png"
import Uni1_Small from "../images/Uni_game_1_Small.png"
import Uni2_Small from "../images/Uni_game_2_Small.png"
import Uni3_Small from "../images/Uni_game_3_Small.png"
//import Uni4_Small from "../images/Uni_game_4_Small.png"
//import Uni5 from "../images/Uni_game_5.png"
import Jam1 from "../images/Gamejam_1.png"
import Jam2 from "../images/Gamejam_2.png"
import Jam1_Small from "../images/Gamejam_1_Small.png"
import Jam2_Small from "../images/Gamejam_2_Small.png"
import Side1 from "../images/Sideproject_1.png"
import Side2 from "../images/Sideproject_2.png"
import Side3 from "../images/Sideproject_3.png"
import Side1_Small from "../images/Sideproject_1_Small.png"
import Side2_Small from "../images/Sideproject_2_Small.png"
import Side3_Small from "../images/Sideproject_3_Small.png"
import Nongame1 from "../images/NonGame_1.png"
import Nongame2 from "../images/NonGame_2.png"
import Nongame1_Small from "../images/NonGame_1_Small.png"
import Nongame2_Small from "../images/NonGame_2_Small.png"
import Art1 from "../images/Art_1.jpg"
import Art2 from "../images/Art_2.png"
import Art3 from "../images/Art_3.png"
import Art4 from "../images/Art_4.png"
import Art1_Small from "../images/Art_1_Small.jpg"
import Art2_Small from "../images/Art_2_Small.png"
import Art3_Small from "../images/Art_3_Small.png"
import Art4_Small from "../images/Art_4_Small.png"
//import Art5 from "../images/Art_5.jpg"
//import Art5_Small from "../images/Art_5_Small.jpg"
import Modeling1 from "../images/Modeling_1.png"
import Modeling2 from "../images/Modeling_2.png"
import Modeling3 from "../images/Modeling_3.png"
import Modeling4 from "../images/Modeling_4.png"
import Modeling1_Small from "../images/Modeling_1_Small.png"
import Modeling2_Small from "../images/Modeling_2_Small.png"
import Modeling3_Small from "../images/Modeling_3_Small.png"
import Modeling4_Small from "../images/Modeling_4_Small.png"
//import Modeling5 from "../images/Modeling_5.jpg"
//import Modeling5_Small from "../images/Modeling_5_Small.jpg"

export default class Projects extends PureComponent {
    render() {
        return (
            <article className="row justify-content-center">
                <div className="mt-4 center_text col-12">
                    <div className="row">
                        <h2 className="page_title col-6 col-md-3 col-xl-2">
                            PROJECTS
                        </h2>
                    </div>
                </div>
                <section className="col-md-11 p-4 mb-3 pt-2">
                    <div style={Project_box} className="row pb-2 project_box_gradient">
                        <h3 className="center_text col-12 pt-1 fw-bold blue_title">Current projects</h3>
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage 
                                        hideDownload={true} hideZoom={true} 
                                        small={Current_1_Small}
                                        large={Current_1}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Current_2_Small}
                                        large={Current_2}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Current_3_Small}
                                        large={Current_3}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Current_4_Small}
                                        large={Current_4}
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="p-3 pb-0 mb-1 pt-1 col-12">
                            My main focus right now is to work on study assignments, above you can see some of the prototypes that I have worked on. A few of these projects are on <a href="https://michellethedragon.itch.io/" rel="noreferrer" target="_blank">itch.io</a> and <a href="https://github.com/MichelleTheDragon" rel="noreferrer" target="_blank">GitHub</a>.
                        </p>
                    </div>
                </section>
                <section className="col-md-11 p-4 mb-3 pt-2 ">
                    <div style={Project_box} className="row pb-2 project_box_gradient">
                        <h3 className="center_text col-12 pt-1 fw-bold blue_title">Untitled RPG game</h3>
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={RPG_Game1_Small}
                                        large={RPG_Game1}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={RPG_Game2_Small}
                                        large={RPG_Game2}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={RPG_Game3_Small}
                                        large={RPG_Game3}
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="p-3 pb-0 mb-1 pt-1 col-12">
                            This is a project I have been working on for some time, it is a multiplayer RPG game in which you travel the world and do whatever you feel like, you can pick up tasks at noticeboards, fight your way to glory & treasure, become the greatest fisher there ever was, or discover untold lands.
                        </p>
                    </div>
                </section>
                <section className="col-md-11 p-4 mb-4 pt-2">
                    <div style={Project_box} className="row pb-2 project_box_gradient">
                        <h3 className="center_text col-12 pt-1 fw-bold blue_title">University projects</h3>
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true"
                                        small={Uni1_Small}
                                        large={Uni1}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Uni2_Small}
                                        large={Uni2}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Uni3_Small}
                                        large={Uni3}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Uni4}
                                        large={Uni4}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*<img className="p-2 pb-1 col-md-3 col-6" src={Uni5} alt=""/> */}
                        <p className="p-3 pb-0 mb-1 pt-1 col-12">
                            While at the university I worked on several game prototypes to be used for semester projects. Some of the tools used for the prototypes were the Kinect (controlling the game character with body movement), and Vuforia (image processing plugin). The prototypes were created using Unity and C#.
                        </p>
                    </div>
                </section>
                <section className="col-md-11 p-4 mb-4 pt-2">
                    <div style={Project_box} className="row pb-2 project_box_gradient">
                        <h3 className="center_text col-12 pt-1 fw-bold blue_title">Game Jams</h3>
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Jam1_Small}
                                        large={Jam1}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Jam2_Small}
                                        large={Jam2}
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="p-3 pb-0 mb-1 pt-1 col-12">
                            I tried joining a few game jams, in which I had 2 days to create a game from scratch. I had a lot of fun and learned a lot.
                        </p>
                    </div>
                </section>
                <section className="col-md-11 p-4 mb-4 pt-2">
                    <div style={Project_box} className="row pb-2 project_box_gradient">
                        <h3 className="center_text col-12 pt-1 fw-bold blue_title">Side projects</h3>
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Side1_Small}
                                        large={Side1}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Side2_Small}
                                        large={Side2}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Side3_Small}
                                        large={Side3}
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="p-3 pb-0 mb-1 pt-1 col-12">
                            I like to sometimes take a break from my current major project and try something different out. Here are a few of the game projects I have been playing around with in order to improve my abilities.
                        </p>
                    </div>
                </section>
                <section className="col-md-11 p-4 mb-4 pt-2">
                    <div style={Project_box} className="row pb-2 project_box_gradient">
                        <h3 className="center_text col-12 pt-1 fw-bold blue_title">Non-game projects</h3>
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Nongame1_Small}
                                        large={Nongame1}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Nongame2_Small}
                                        large={Nongame2}
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="p-3 pb-0 mb-1 pt-1 col-12">
                            I made a few non-game projects in order to help me with certain things. I wanted a timer/clock for my second monitor in order to manage time, and a simple task list that can be saved and a random task can be chosen from, so I made them myself.
                        </p>
                    </div>
                </section>
                <section className="col-md-11 p-4 mb-4 pt-2">
                    <div style={Project_box} className="row pb-2 project_box_gradient">
                        <h3 className="center_text col-12 pt-1 fw-bold blue_title">Art</h3>
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Art1_Small}
                                        large={Art1}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Art2_Small}
                                        large={Art2}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Art3_Small}
                                        large={Art3}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Art4_Small}
                                        large={Art4}
                                    />
                                </div>
                                {/*<img className="p-2 pb-1 col-md-3 col-6" src={Art5} alt=""/>*/}
                            </div>
                        </div>
                        <p className="p-3 pb-0 mb-1 pt-1 col-12">
                            One of my passions is drawing/painting, and I'd like to improve this skill much more. These are a few of the pieces I have drawn/painted.
                        </p>
                    </div>
                </section>
                <section className="col-md-11 p-4 pt-2">
                    <div style={Project_box} className="row pb-2 project_box_gradient">
                        <h3 className="center_text col-12 pt-1 fw-bold blue_title">3D work</h3>
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Modeling1_Small}
                                        large={Modeling1}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Modeling2_Small}
                                        large={Modeling2}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Modeling3_Small}
                                        large={Modeling3}
                                    />
                                </div>
                                <div className="p-2 pb-1 col-md-3 col-6">
                                    <ModalImage hideDownload="true" hideZoom="true"
                                        small={Modeling4_Small}
                                        large={Modeling4}
                                    />
                                </div>
                                {/*<img className="p-2 pb-1 col-md-3 col-6" src={Modeling5} alt=""/>*/}
                            </div>
                        </div>
                        <p className="p-3 pb-0 mb-1 pt-1 col-12">
                            Here you can see a few of the images I have rendered, and a few pictures from my game project for which I create my own assets.
                        </p>
                    </div>
                </section>
                {/* <section className="col-md-6 p-3 mb-4">
                    <div style={Project_box}>
                        <img className="p-2 pb-1" src={Uni1} alt=""/>
                        <h3 className="center_text">Untitled RPG game</h3>
                        <p className="p-3 pb-1 pt-1">
                            This is a project I have been working on for some time, it is a multiplayer RPG game in which you travel the world and do whatever you feel like, you can pick up tasks at noticeboards, fight your way to glory & treasure, become the greatest fisher there ever was, or discover untold lands.
                        </p>
                    </div>
                </section> */}
            </article>
        )
    }
}

const Project_box = {
    border: "0px dotted #4c4c4e",
    marginTop: "2.5rem",
    backgroundColor: "#23252b",
}