import React, { PureComponent } from 'react'

//images
import Me from "../images/Me.png"

export default class About extends PureComponent {
    render() {
        return (
            <article className="row">
                <div className="mt-4 center_text col-12">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="page_title col-6 col-md-3 col-xl-2 rainbow_text">
                                ABOUT ME
                            </h2>
                        </div>
                        <div className="col-12 justify-content-center d-flex">
                            <p className="mt-2 col-lg-8 fst-italic">
                                My name is Michelle, I'm {calculateAge()} years old and from Denmark. I love solving problems and creating things, I have worked on mainly software applications, but also one physical. Below you can see some of the things I have worked on in my free time and during education.
                            </p>
                        </div>
                    </div>
                </div>
                <section className="col-md-8 order-md-1 order-2">
                    <div className="row m-0 mb-3">
                        <h5 className="fw-bold center_text cv_title blue_title">
                            Expertise
                        </h5>
                        <hr className="m-0 mb-1 center_text"/>
                        <div className="col-xl-4 col-md-6">
                            <h5 className="fw-bold ">
                                Software 
                            </h5>
                            <hr className="m-0 mb-1 center_text"/>
                            <div className="row">
                                <p className="col-4">
                                    Photoshop:<br />
                                    3D Max <br />
                                    Unity: <br /><br />
                                </p>
                                <div className="col-8 skill_holder_1">
                                    <i className="fas fa-paint-brush me-1 achieved_skill"></i>
                                    <i className="fas fa-paint-brush me-1 achieved_skill"></i>
                                    <i className="fas fa-paint-brush me-1 achieved_skill"></i>
                                    <i className="fas fa-paint-brush me-1 achieved_skill"></i>
                                    <i className="fas fa-paint-brush no_skill"></i>
                                    <br />
                                    <i className="fas fa-apple-alt me-1 achieved_skill" />
                                    <i className="fas fa-apple-alt me-1 achieved_skill" />
                                    <i className="fas fa-apple-alt me-1 achieved_skill" />
                                    <i className="fas fa-apple-alt me-1 no_skill" />
                                    <i className="fas fa-apple-alt no_skill" />
                                    <br />
                                    <i className="fab fa-unity me-1 achieved_skill" />
                                    <i className="fab fa-unity me-1 achieved_skill" />
                                    <i className="fab fa-unity me-1 achieved_skill" />
                                    <i className="fab fa-unity me-1 achieved_skill" />
                                    <i className="fab fa-unity no_skill" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <h5 className="fw-bold">
                                Coding Languages 
                            </h5>
                            <hr className="m-0 mb-1 center_text"/>
                            <div className="row">
                                <p className="col-4">
                                    HTML5: <br />
                                    CSS3: <br />
                                    React: <br />
                                    C#: <br />
                                    Python: <br />
                                </p>
                                <div className="col-8 skill_holder_2">
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code no_skill" />
                                    <br />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code no_skill" />
                                    <br />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code no_skill" />
                                    <i className="fas fa-code no_skill" />
                                    <br />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code no_skill" />
                                    <br />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code achieved_skill" />
                                    <i className="fas fa-code no_skill" />
                                    <i className="fas fa-code no_skill" />
                                    <i className="fas fa-code no_skill" />
                                </div>
                            </div>
                        </div><div className="col-xl-4 col-md-6">
                            <h5 className="fw-bold">
                                Languages 
                            </h5>
                            <hr className="m-0 mb-1 center_text"/>
                            <div className="row">
                                <p className="col-4">
                                    Danish: <br />
                                    English: <br />
                                </p>
                                <div className="col-8 skill_holder_2">
                                    <i className="far fa-comment-dots achieved_skill" />
                                    <i className="far fa-comment-dots achieved_skill" />
                                    <i className="far fa-comment-dots achieved_skill" />
                                    <i className="far fa-comment-dots achieved_skill" />
                                    <i className="far fa-comment-dots achieved_skill" />
                                    <br />
                                    <i className="far fa-comment-dots achieved_skill" />
                                    <i className="far fa-comment-dots achieved_skill" />
                                    <i className="far fa-comment-dots achieved_skill" />
                                    <i className="far fa-comment-dots achieved_skill" />
                                    <i className="far fa-comment-dots achieved_skill" />
                                </div>
                            </div>
                        </div>
                        <hr className="m-0 mb-1 center_text"/>
                    </div>
                    <div>
                        <h5 className="fw-bold center_text cv_title blue_title">
                            Education
                        </h5>
                        <hr className="m-0 mb-1 center_text"/>
                        <p>
                            <div className="fst-italic">
                                <div className="fw-bold NoWrap ">Computer Science</ div> - Dania Games, Erhvervsakademi Dania Grenaa (2021 - Ongoing)<br/>
                            </div>
                            <div className="ps-3">
                                Education focused on learning the fundamentals of programming and project development by creating games using the HAGI method, which involves having one course at a time, learning theory first and thereafter doing a project using what we have learned. The main coding language taught has been C#, but I have gotten to work a bit with Python and C++ for course projects.   
                                <br /><br />
                            </div>
                            <div className="fst-italic">
                                <div className="fw-bold NoWrap ">Web Developer</ div> - 3D College, Videndjurs Grenaa (2020 - 2021)<br/>
                            </div>
                            <div className="ps-3">
                                I was taught how to use applications to create 3D renders, image editing, and web development.
                                <br /><br />
                            </div>
                        </p>
                    </div>

                    <div>
                        <h5 className="fw-bold center_text cv_title blue_title">
                            Work Experience
                        </h5>
                        <hr className="m-0 mb-1 center_text"/>
                        <p>
                            <div className="fst-italic">
                                <div className="fw-bold NoWrap">Forestry Helper</ div> - Haraldskær, Vejle jobcenter (2019)<br/>
                            </div>
                            <div className="ps-3">
                                Assisted in maintaining bushes and trees in public areas, like pathways.<br /><br />
                            </div>
                            
                            <div className="fst-italic">
                                <div className="fw-bold NoWrap">Assistant</ div> - Aid Depot, Esbjerg jobcenter (2019) <br />
                            </div>
                            <div className="ps-3">
                                Helped in cleaning the storage, testing if used aids could be reused, arranging aids in a tidy manor, and assisting mechanics in repairs on electrical scooters.
                                <br/><br/>
                            </div> 
                        </p>
                    </div>
                    
                    <div>
                        <h5 className="fw-bold center_text cv_title blue_title">
                            Other
                        </h5>
                        <hr className="m-0 mb-1 center_text"/>
                        <p>
                            <div className="fst-italic">
                                <div className="fw-bold NoWrap">Medialogy</div> - Aalborg University Esbjerg (2014 - 2019, Not completed) 
                            </div>
                            <div className="ps-3">
                                During my time here I took part in several projects for which prototype applications were created in order to test theories. I mostly focused on programming and design. I completed 5 of 6 semesters.
                            </div>
                        </p>
                    </div>
                </section>
                <section className="col-md-4  order-md-2 order-1 m-0">
                    <div className="p-2 col-7 row" style={Portrait}>
                        <img className="mb-2"  src={Me} alt=""/>
                    </div>
                    <div className="row">
                        <hr className="m-0 mb-1 center_text"/>
                        <p className="col-3 mb-0">
                            Name: 
                        </p>
                        <p className="col-9 mb-0">
                            Michelle Alexandra Østerbye Hansen
                        </p>
                        <p className="col-3 mb-0">
                            Age: 
                        </p>
                        <p className="col-9 mb-0">
                            {calculateAge()} years
                        </p>
                        <p className="col-3 mb-0">
                            Residence: <br />
                        </p>
                        <p className="col-9 mb-0">
                            Denmark, Eastern Jutland<br />
                        </p>
                        <p className="col-3 mb-2">
                            Occupation <br />
                        </p>
                        <p className="col-9 mb-2">
                            Student, Computer Science<br />
                        </p>
                        <hr className="m-0 mb-3 center_text"/>
                    </div>
                </section>
            </article>
        )
    }
}

function calculateAge(){
    var birthDay =  new Date("03/15/1991");
    var currentDate = new Date();
    var age = currentDate.getFullYear() - birthDay.getFullYear();
    var m = currentDate.getMonth() - birthDay.getMonth();
    if(m < 0 || (m === 0 && currentDate.getDate() < birthDay.getDate())){
        age--;
    }
    return age;
}

const Portrait = {
    display: "block",
    margin: "0 auto",
}