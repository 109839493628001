import './App.css';
import { BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";

// Layout
import Header from './components/layout/Header'
import Nav from './components/layout/Nav'
import Footer from './components/layout/Footer'

// Pages
import Index from './components/pages/Index'
import Projects from './components/pages/Projects'
import About from './components/pages/About'
import Contact from './components/pages/Contact'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Nav />
        <div className="container MainBody mb-5">
          <Switch>
            <Route exact path="/projects" component={Projects}/>
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/" component={Index} />
            <Route render={() => <Redirect to={{pathname: "/"}} />} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
