import React, { Component } from 'react'
import { Link } from "react-router-dom";

export class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row mt-1">

                        <h4 className="pb-1 pt-1 center_text col-md-6 NoWrap">&#169; {new Date().getFullYear()} - Michelle A. Ø. Hansen</h4>
                        <div className="col-md-6 center_text pt-1 NoWrap">
                            {/* <h4 className="pe-3 NoWrap">Contact:</h4> */}
                            <div className="NoWrap">
                                <Link to="/contact"  className="me-4">
                                    <i className="far fa-envelope fa-2x alignContentMiddle MailMe"></i>
                                </Link>
                                <a target="_blank" href="https://www.linkedin.com/in/michelle-alexandra-%C3%B8sterbye-hansen-529502140/" className="me-4" rel="noreferrer">
                                    <i className="fab fa-linkedin-in fa-2x alignContentMiddle LinkedIn"></i>
                                </a>
                                {/* <a target="_blank" href="https://www.deviantart.com/fioza" className="me-4" rel="noreferrer">
                                    <i className="fab fa-deviantart fa-2x alignContentMiddle DeviantArt"></i>
                                </a> */}
                                <a target="_blank" href="https://www.facebook.com/profile.php?id=100013171863669" className="me-4" rel="noreferrer">
                                    <i className="fab fa-facebook-f fa-2x alignContentMiddle Facebook"></i>
                                </a>
                                <a target="_blank" href="https://github.com/MichelleTheDragon" className="me-4" rel="noreferrer">
                                    <i className="fa fa-github fa-2x alignContentMiddle Github"></i>
                                </a>
                                <a target="_blank" href="https://michellethedragon.itch.io/" rel="noreferrer">
                                    <i className="fab fa-itch-io fa-2x alignContentMiddle Itch_io"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
