
import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser"

const Contact = () => {
  // const [status, setStatus] = useState("Submit");
  // const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     setStatus("Sending...");
  //     const { name, email, subject, message } = e.target.elements;
  //     let details = {
  //       name: name.value,
  //       email: email.value,
  //       subject: subject.value,
  //       message: message.value,
  //     };
      
  //};// 

  const my_form = useRef();
  const [statusButton, setButtonStatus] = useState("Submit");
  const [status, setStatus] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonStatus("Sending...");
    setStatus("");
    emailjs.sendForm(
      "service_iaqk3wb", 
      "template_7c0wpf9", 
      my_form.current, 
      "d7xwgxlda3s9Iux8d").then((result) => {
        console.log(result.text);
        setStatus("Message has been sent");
        setButtonStatus("Submit");
      }, (error) => {
        console.log(error.text);
        setStatus("Message could not be sent");
        setButtonStatus("Submit");
      });
      //const { name, email, subject, message } = e.target.elements;
      //const sendMail = (mail) => {
      //  fetch("/send", {
      //    method: "post",
      //    body: mail,
      //  }).then((result) => {
      //    setStatus("Submit");
      //    return result.json();
      //  });
      //}
      //sendMail(e.FormData)
  };
  //const form = document.getElementById("contact-form"); 
  //form.addEventListener("submit", (event) => {
  //  event.preventDefault();
  //
  //  let mail = new FormData(form);
  //
  //  sendMail(mail);
  //})
  return (
      <div className="row justify-content-center">
        <div className="mt-4 center_text col-12 mb-3">
          <div className="row">
              <h2 className="page_title col-6 col-md-3 col-xl-2">
                  CONTACT
              </h2>
          </div>
        </div>
        <div className="col-12 col-lg-6 mt-2">
          <div className="mb-5 center_text">
            <p>
              If you have any further inquiries, feel free to reach out to me.
            </p>
          </div>
          <form ref={my_form} onSubmit={handleSubmit} id="form">
            <div className="row">
              <div className="pb-3 col-12 col-md-6">
                <label htmlFor="name" className="required pb-2">Name:</label><br/>
                <input className="contact_input p-1 col-12" name="name" type="text" id="name" placeholder="Name..." required />
                    
              </div>
              <div className="pb-3 col-12 col-md-6">
                <label htmlFor="email"className="required pb-2">Email:</label><br/>
                <input className="contact_input p-1 col-12" name="email" type="email" id="email" placeholder="Email..." required />
              </div>
              <div className="pb-3 col-12">
                <label htmlFor="subject"className="required pb-2">Subject:</label><br/>
                <input  className="contact_input p-1 col-12" name="subject" type="text" id="subject" placeholder="Subject..." required />
              </div>
              <div className="col-12">
                <label htmlFor="message"className="required pb-2">Message:</label><br/>
                <textarea className="contact_text col-12 p-1" name="message" id="message" placeholder="Your Message..." required />
                <input type="submit" className="col-12 col-md-3 p-1 mt-2" value={statusButton} />
              </div>
            </div>
          </form>
          <p >{status}</p>
        </div>
      </div>
  );
};

export default Contact;