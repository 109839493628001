import React, { PureComponent } from 'react'
//import { Link } from 'react-router-dom'

import Welcome from "../images/RPG_tavern.png"
import Current from "../images/Current_2.png"

export default class Index extends PureComponent {
    render() {
        return (
            <article>
                <div className="row">
                    {/*<section className="center_text pb-4 col-12">
                        <div className="row">
                            <h2 className="page_title col-6 col-md-3 col-xl-2">
                                Welcome
                            </h2>
                        </div>
                    </section>*/}
                    <div className="mt-4 col-12">
                        <div className="row">
                            <section className="col-lg-6 mb-1">
                                <div className="p-2  row">
                                    <div className="p-0 row m-0 col-12" style={WelcomeBox}>
                                        <div className="col-sm-7 col-9 p-3 m-0">
                                            <h3 className="pb-3">Welcome </h3>
                                            <p>
                                                Hello and welcome to my portfolio website. Here I upload projects I've worked on as well as write about  interesting things I find while educating myself.
                                                <br /><br />
                                            </p>
                                        </div>
                                        <div className="col-sm-7 col-5 d-flex p-0 gradient_img" style={ImgBox}>
                                            <img className="ImgIndex" style={CurrentImg} src={Welcome} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="col-lg-6 mb-3 ">
                                <div className="p-2  row">
                                    <div className="p-0 row m-0 col-12" style={CurrentBox}>
                                        <div className="col-sm-7 col-9 p-3 m-0">
                                            <h3 className="pb-3">Current</h3>
                                            <p>
                                                Currently I attend the Computer Science study at Dania Games in Grenaa. The education is highly focused on creating games in order to get an understanding for programming.
                                                <br /><br />
                                            </p>
                                        </div>
                                        <div className="col-sm-7 col-5 d-block p-0 gradient_img" style={ImgBox}>
                                            <img className="ImgIndex" style={CurrentImg} src={Current} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <section className="col-12 p-2">
                        <h4>Latest Activity</h4>
                        <div className="latest_content">
                            {Activities(14,  3, 2023, "Improved the website and added github link in footer")}
                            {Activities(8,  3, 2023, "I am currently on the lookout for an internship for next semester")}
                            {Activities(20,  1, 2023, "Improving the website in order to make updates easier.")}
                            {Activities(6,  9, 2021, "I have started first semester of the Computer Science education.")}
                            {Activities(10,  6, 2021, "I have applied at the Computer Science study at Dania Games, also located in Grenaa, with a start in September.")}
                            {Activities(10,  6, 2021, "Website has been reworked with react and bootstrap")}
                        </div>
                    </section>
                
                </div>
            </article>
        )
    }
}

const WelcomeBox = {
    backgroundColor: "#2ba141",
    position: "relative",
    height: "250px",
}

const CurrentBox = {
    backgroundColor: "#a12b9a",
    position: "relative",
    height: "250px",
}

const ImgBox = {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

const CurrentImg = {
    flexShrink: "0",
    objectFit: "cover",
}

var n = 0;

function Activities(day, month, year, myActivity) {
    n += 1;
    return (
        <div style={ActivityBG(n)}>
            <p className="mb-0 p-2">
                {CheckValueUnderTen(day)}/{CheckValueUnderTen(month)}/{year}&nbsp; - &nbsp;{myActivity}
            </p>
        </div>
    );
}

function ActivityBG(number){
    if(number%2 === 0){
        return Activity1
    } else {
        return Activity2
    }
}

const Activity1 = {
    backgroundColor: "#d2d2d2",
    color: "black",
}

const Activity2 = {
    backgroundColor: "white",
    color: "black",
}

function CheckValueUnderTen(number){
    var newNumber = ""
    if(number < 10){
        newNumber += "0"
    }
    newNumber += number
    return newNumber
}